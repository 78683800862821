<template>
  <div style="min-height: 600px;">
    <div>
      <div class="has-background-secondary">
        <div class="container content py-6 mb-6">
          <h1 class="has-text-white my-0">Mobile App</h1>
        </div>
      </div>

      <div class="container content pb-6">
        <div style="max-width: 850px">
          <p>
            <strong>SearchRockit™</strong> is simply the safest and most secure
            way to search the web, period. Double that for a mobile device. Ever
            wonder why we do something as personal as search in an incredibly
            insecure device like a browser? <strong>SearchRockit™</strong> looks
            and feels like a browser, but most of the browser is running on our
            servers, and all they communicate to your device are instructions on
            how to paint the screen. <strong>SearchRockit™</strong> uses
            <strong>Gibiru Technology</strong> which originates in the hacker
            world, to the mainstream.
          </p>

          <p>
            No website can set anything on your device through
            <strong>SearchRockit™</strong> and
            <strong>SearchRockit™</strong> has no access to any of your phone’s
            data, personal, location, or anything else.
          </p>
        </div>
        <div class="mt-6">
          <b-button
            class="mr-3 mb-4 is-medium has-text-weight-bold"
            tag="a"
            :href="$settings.IOS_APP_LINK"
            target="_blank"
            type="is-secondary"
            >iOS App Store</b-button
          >
          <b-button
            tag="a"
            :href="$settings.ANDROID_APP_LINK"
            target="_blank"
            type="is-secondary is-medium has-text-weight-bold"
            >Google Play</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileApp"
};
</script>